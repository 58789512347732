<template>
  <div>
    <v-card>
      <v-card>
        <br />
        <v-card-title class="title">
          <v-layout row wrap>
            <v-flex xs6 sm6>
              Replace By
              <hr />
              <br />
              <v-batch-field
                :only-previous="true"
                v-model="byBatchId"
                :company-id="schoolId"
              ></v-batch-field>
              <v-grade-field
                v-model="byGradeId"
                :company-id="schoolId"
                :batch-id="byBatchId"
              ></v-grade-field>
              <v-section-field
                @onSectionChange="fetchStudent"
                v-model="bySectionId"
                :grade-id="byGradeId"
              ></v-section-field>
            </v-flex>

            <v-flex xs6 sm6>
              Replace From
              <hr />
              <br />
              <v-batch-field
                :only-current="true"
                v-model="fromBatchId"
                :company-id="schoolId"
              ></v-batch-field>
              <v-grade-field
                @selectedGrade="gradesSection"
                :inside="true"
                v-model="fromGradeId"
                :company-id="schoolId"
                :batch-id="fromBatchId"
              ></v-grade-field>
              <v-section-field
                @onSectionChange="fetchStudentAndManage"
                v-model="fromSectionId"
                :sections="toSectionList"
                :grade-id="fromGradeId"
              ></v-section-field>
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>
      <br />
      <v-container>
        <center>
          <v-chip class="mr-4" small color="error" label>CURRENT ENROLL</v-chip>
          &nbsp;&nbsp; IS REPLACED BY &nbsp;&nbsp;
          <v-chip small class="ml-4" color="primary" label
            >PREVIOUS ENROLL</v-chip
          >
        </center>
      </v-container>
      <br />
      <br />
      <v-data-table
        :headers="headers"
        :items="!finalStudentList.length ? studentList : finalStudentList"
        :options.sync="pagination"
        :footer-props="footerProps"
        footer-props.items-per-page-options="rowsPerPageItems"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:item="{ index, item }">
          <tr>
            <td>{{ index + 1 }}</td>
            <td class="text-xs-left">
              <strong>{{ item.name }}</strong>
            </td>

            <td class="text-xs-center">
              <v-chip small label color="primary" text-color="white">
                {{ item.enroll_code }}
              </v-chip>
              &nbsp;
              <v-chip small label color="error" text-color="white">
                {{ item.replace_enroll }}
              </v-chip>
            </td>
            <td class="text-right">
              <v-btn class="success" :disabled="true">
                Replace
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-overlay :value="updating">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-container>
        <v-btn
          @click="replaceNow"
          block
          large
          :disabled="replaceData.length < 1"
          >Replace Now ({{ replaceData.length }}) Records
        </v-btn>
      </v-container>
      <br />
    </v-card>
  </div>
</template>

<script>
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  props: ["schoolId"],
  data() {
    return {
      updating: false,
      // byBatchId: '30',
      byBatchId: "",
      byGradeId: "",
      // bySectionId: '982',
      bySectionId: "",
      // fromBatchId: '58',
      fromBatchId: "",
      fromGradeId: "",
      // fromSectionId: '1633',
      fromSectionId: "",
      toSectionList: [],
      studentList: [],
      finalStudentList: [],
      rowsPerPageItems: [5, 10, 25, 50, 75],
      pagination: {
        itemsPerPage: 100,
        // sortBy: 'grade'
      },

      headers: [
        { text: "#", align: "left", value: "id", width: 20, sortable: false },
        { text: "Name", align: "left", value: "name", sortable: false },
        {
          text: "Enroll Code",
          align: "left",
          value: "name",
          width: 500,
          sortable: true,
        },
        // {text: 'Marks Entry (Start/End)', align: 'left', value: 'name', sortable: false, width: 250},
        // {text: 'Action', align: 'right', sortable: false},
        {
          text: "Action",
          align: "right",
          value: "name",
          width: 100,
          sortable: false,
        },
      ],
    };
  },
  watch: {},
  mounted() {
    // this.fetchStudent(982);
  },
  computed: {
    replaceData() {
      const replaceData = [];
      this.finalStudentList.map((item) => {
        if (
          item.id === item.replace_std_id &&
          item.enroll_code !== item.replace_enroll
        ) {
          replaceData.push({
            std_id: item.id,
            by: item.enroll_code,
            from: item.replace_enroll,
          });
        }
      });
      return replaceData;
    },
  },
  methods: {
    gradesSection(grade) {
      this.toSectionList = grade.sections;
    },

    fetchStudent(sectionId) {
      if (!sectionId) return;
      this.updating = true;
      this.$rest
        .get(
          "/api/search-student?rowsPerPage=300&sectionId=" +
            sectionId +
            "&super_mode_company_id=" +
            this.schoolId +
            "&batchId=" +
            this.byBatchId
        )
        .then((res) => {
          this.studentList = res.data.data;
          this.fetchStudentAndManage(this.fromSectionId);
        })
        .finally(() => {
          this.updating = false;
        });
    },

    fetchStudentAndManage(sectionId) {
      if (!sectionId) return;
      this.updating = true;
      this.$rest
        .get(
          "/api/search-student?rowsPerPage=300&sectionId=" +
            sectionId +
            "&super_mode_company_id=" +
            this.schoolId +
            "&batchId=" +
            this.fromBatchId
        )
        .then((res) => {
          const finalStudentList = [];

          this.studentList.map((item) => {
            res.data.data.map((item2) => {
              if (
                item.id === item2.id &&
                item.enroll_code !== item2.enroll_code
              ) {
                finalStudentList.push({
                  name: item.name,
                  replace_enroll: item2.enroll_code,
                  enroll_code: item.enroll_code,
                  replace_std_id: item2.id,
                  id: item.id,
                });
              }
            });
          });
          this.finalStudentList = finalStudentList;
        })
        .finally(() => {
          this.updating = false;
        });
    },
    replaceNow() {
      this.updating = true;
      this.$rest
        .post("/api/super/enroll/correction?companyId=" + this.schoolId, {
          std_list: this.replaceData,
        })
        .then((res) => {
          this.fetchStudent(this.bySectionId);
        })
        .finally((err) => {
          this.updating = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
